/**
 * Transforms CSV data source fields into an array of objects.
 * Each object represents a row in the CSV data, with field names as object keys and corresponding values.
 * @param {Array} fields - The CSV data source fields.
 * @returns {Array} - An array of objects representing the transformed CSV data.
 */
function transformCsvDSToObjs(fields) {
  const objs = []
  fields.forEach((field, index) => {
    if (index === 0) {
      field.values.forEach(value => {
        const obj = {}
        obj[field.name] = value
        objs.push(obj)
      })
    } else {
      field.values.forEach((value, index) => {
        objs[index][field.name] = value
      })
    }
  })
  return objs
}

function setStatus(devices, valor, indice) {
  if (!isNaN(valor)) {
    if (valor < 5) {
      devices.Api[indice].status = 'ok'
    } else if (valor <= 10) {
      devices.Api[indice].status = 'warning'
    } else {
      devices.Api[indice].status = 'critical'
    }
  }
}

const MK_state_mapping = {
  UP: 'ok',
  DOWN: 'critical',
  UNREACH: 'critical',
  PENDING: 'pending',
  IGNORED: 'ignored',
}
// minor, warning, critical, ok

export function processDataDevices(data) {
  const series = {}
  data.series.forEach(serie => {
    if (serie.first) {
      // Dev
      try {
        series[serie.refId] = JSON.parse(serie.first[0])
      } catch (e) {
        console.log(e)
      }
    }
    if (serie.fields) {
      // Prod
      if (serie.fields.length > 1) {
        series[serie.refId] = transformCsvDSToObjs(serie.fields)
      } else {
        try {
          series[serie.refId] = JSON.parse(serie.fields[0]['values'][0])
        } catch (e) {
          console.log(e)
        }
      }
    }
  })

  const devices = {
    Torniquete: [
      {
        hostname: '-Torniquete Entrada I-',
        ip: '10.255.206.133',
        status: 'ok',
      },
      {
        hostname: '-Torniquete Entrada D-',
        ip: '10.255.206.134',
        status: 'ok',
      },
      {
        hostname: '-Torniquete Salida I-',
        ip: '10.255.206.135',
        status: 'ok',
      },
      {
        hostname: '-Torniquete Salida D-',
        ip: '10.255.206.136',
        status: 'ok',
      },
    ],
    Equipo: [
      {
        hostname: '-Caja 1-',
        ip: '10.255.218.5',
        status: 'ok',
      },
      {
        hostname: '-Caja 2-',
        ip: '10.255.218.4',
        status: 'ok',
      },
      {
        hostname: '-Caja 3-',
        ip: '10.255.218.9',
        status: 'ok',
      },
      {
        hostname: '-Caja 4-',
        ip: '10.255.218.2',
        status: 'ok',
      },
    ],
    Api: [
      {
        hostname: 'api_skyweb',
        ip: 'api_skyweb',
        status: 'ok',
      },
      {
        hostname: 'Janus',
        ip: 'Janus',
        status: 'ok',
      },
      {
        hostname: 'SkyPres',
        ip: 'SkyPres',
        status: 'ok',
      },
    ],
  }

  if (series['statusEquipos']) {
    Object.values(devices).forEach(lstDevices => {
      lstDevices.forEach(device => {
        let tmp = series['statusEquipos'].find(d => d.host === device.ip)
        if (tmp) {
          device.status = MK_state_mapping[tmp.host_state]
        }
      })
    })
  }

  if (series['statusApiPrincipal'] && series['statusApiPrincipal'][0] != null) {
    setStatus(devices, series['statusApiPrincipal'][0].values, 0)
  }
  if (series['statusApiJanus'] && series['statusApiJanus'][0] != null) {
    setStatus(devices, series['statusApiJanus'][0].values, 1)
  }
  if (series['statusApiSkyPres'] && series['statusApiSkyPres'][0] != null) {
    setStatus(devices, series['statusApiSkyPres'][0].values, 2)
  }

  return devices
}
